import React from "react";
import Box from "@mui/material/Box";

export const NotFound = () => {
  return (
    <Box>
      <h1 className="notFound">Lütfen tekrar giriş yapın</h1>
      <button
        className="ui button returnToStoreSelection"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = `/`;
        }}
      >
        Giriş Yap
      </button>
    </Box>
  );
};
