import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import generateHash from "../services/generateHash";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { dateAdd } from "../services/utils";
import { get, set } from "../services/cache";
import Logo from "../assets/img/dkt-tech-logo.png";

export const Login = () => {
  const redirect = useNavigate();
  const userInfo = get("fedidLoginAccessToken");

  const [fedidUrl, setFedidUrl] = useState("");

  useEffect(() => {
    // if (userInfo) {
    //   return redirect("/homepage");
    // }

    const crypto = generateHash();
    set({
      key: "fedidCodeVerifier",
      data: crypto,
      expires: dateAdd(new Date(), "second", 7199).toISOString(),
    });
    let url = `${process.env.REACT_APP_LOGIN_URL}&code_challenge=${crypto}&code_challenge_method=plain`;
    setFedidUrl(url);
  }, []);

  return (
    <Box className="login-main-container">
      <div className="loginContainer">
        <div className="buttonArea">
          <h1 className="welcomeText">Spending Survey'e Hoşgeldin</h1>
          <Button className="loginButton" variant="contained" href={fedidUrl}>
            Decathlon Login
          </Button>
        </div>
        <div className="logo-container">
          <img
            className="dkt-logo"
            src={Logo}
            alt="Decathlon Technology Logo"
          />
        </div>
      </div>
    </Box>
  );
};
