import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { get } from "../services/cache";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

export const Homepage = () => {
  const redirect = useNavigate();

  // Access Login
  const userInfo = get("fedidLoginAccessToken");
  const bearerToken = "Bearer " + userInfo?.data?.access_token;
  const postMethodUrl = process.env.REACT_APP_NEW_SURVEY;
  const apiKey = process.env.REACT_APP_API_KEY;

  const bannedChars = ["-", "+", "e"];

  const handleAmount = (event) => {
    if (event.target.value.length >= 9 || event.target.value === "0") {
      event.target.value = "";
    }
    setAmount(event.target.value);
  };

  // Alert States
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setShowFail] = useState(false);

  // Location States
  const [countryResults, setCountryResults] = useState([]);
  const [provinceResults, setProvinceResults] = useState([]);
  const [citiesResults, setCitiesResults] = useState([]);
  const [neighbourResults, setNeighbourResults] = useState([]);

  // Form Values States
  const [country, setCountry] = useState(1);
  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);
  const [neighbourhood, setNeighbourhood] = useState([]);
  const [transportation, setTransportation] = useState(0);
  const [gender, setGender] = useState(0);
  const [amount, setAmount] = useState(0);
  const [formSent, setFormSent] = useState(false);

  // Counter State
  const [surveyCounter, setSurveyCounter] = useState("");

  // Reset States
  const [neighVals, setNeighVals] = useState([]);
  const [dist, setDist] = useState([]);
  const [provinceVals, setProvinceVals] = useState([]);

  // For Crypto
  const [nameOne, setNameOne] = useState();
  const [nameTwo, setNameTwo] = useState();
  const [nameThree, setNameThree] = useState();
  const [nameFour, setNameFour] = useState();

  useEffect(() => {
    if (!userInfo || !userInfo?.data?.access_token) {
      return redirect("/notfound");
    }

    // Crypto Values
    const [nameOne, nameTwo, nameThree, nameFour] = [
      new Uint32Array(1),
      new Uint32Array(1),
      new Uint32Array(1),
      new Uint32Array(1),
    ].map((array) => {
      window.crypto.getRandomValues(array);
      return array[0];
    });

    setNameOne(nameOne);
    setNameTwo(nameTwo);
    setNameThree(nameThree);
    setNameFour(nameFour);

    setTimeout(() => {
      completedCounter();
    }, 500);
  }, []);

  const provinceValue = (value) => {
    setProvinceVals(value);
  };

  const neighValue = (value) => {
    setNeighVals(value);
  };

  const distValue = (value) => {
    setDist(value);
  };

  const clearProvince = () => {
    setProvinceVals([]);
  };

  const clearNeighbour = () => {
    setNeighVals([]);
  };

  const clearDistrict = () => {
    setDist([]);
  };

  const completedCounter = () => {
    const headers = new Headers();
    headers.append("x-api-key", apiKey);
    headers.append("Authorization", `${bearerToken}`);

    fetch(`${process.env.REACT_APP_API_BASE_URL}/surveys/completed`, {
      method: "GET",
      headers,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data) => setSurveyCounter(data));
  };

  const getCountries = async () => {
    if (countryResults?.length === 0) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/locations/countries`,
        {
          method: "GET",
          headers: {
            "x-api-key": apiKey,
            Authorization: `${bearerToken}`,
          },
        }
      );
      const json = await response.json();
      setCountryResults(json?.data);
    }
  };

  const getProvinces = (id) => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        "x-api-key": apiKey,
        Authorization: `${bearerToken}`,
      }),
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/locations/${id}/provinces`,
      requestOptions
    )
      .then((response) => response.json())
      .then((json) => setProvinceResults(json?.data));
  };

  const loadProvinceList = () => {
    if (country === 1 && provinceResults?.length === 0) {
      getProvinces("TR");
    }
  };

  function getDistricts(id) {
    setProvince(id);
    const myHeaders = new Headers({
      "x-api-key": apiKey,
      Authorization: bearerToken,
    });

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/locations/provinces/${id}/districts`,
      requestOptions
    )
      .then((response) => response.json())
      .then((json) => setCitiesResults(json?.data));
  }

  function getNeighbourHood(id) {
    setDistrict(id);
    const myHeaders = new Headers({
      "x-api-key": apiKey,
      Authorization: bearerToken,
    });

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/locations/districts/${id}/neighbourhoods`,
      requestOptions
    )
      .then((response) => response.json())
      .then((json) => setNeighbourResults(json?.data));
  }

  function sendForm(event) {
    document.getElementById("send-form-button").disabled = true;
    setFormSent(true);

    event.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", apiKey);
    myHeaders.append("Authorization", `${bearerToken}`);

    var formData = JSON.stringify({
      countryId: parseInt(country),
      provinceId: parseInt(province),
      districtId: parseInt(district),
      neighbourhoodId: parseInt(neighbourhood),
      genderId: parseInt(gender),
      transportationId: parseInt(transportation),
      amount: parseFloat(amount),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch(postMethodUrl, requestOptions)
      .then((response) => response.text())
      .then((data) => {
        let parsedData = JSON.parse(data);
        if (parsedData?.status === "200") {
          setShowSuccess(true);
        } else {
          setShowFail(true);
        }
      })
      .catch(() => setShowFail(true));

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  const transportationData = [
    { id: "1", name: "Özel Araç" },
    { id: "2", name: "Otobüs" },
    { id: "3", name: "Metro" },
    { id: "4", name: "Yaya" },
    { id: "5", name: "Taksi" },
    { id: "6", name: "Bisiklet - Motorsiklet - Scooter" },
  ];

  return (
    <Box>
      <div className="App">
        <div className="form-title">
          <h1>Spending Survey</h1>
        </div>

        <div className="description">
          <span>
            Ankete katıldığınız için teşekkür ederiz{" "}
            <strong>*gerekli alan</strong>
            <div className="completed-surveys">
              <span>
                Tamamlanan Anket Sayısı: <strong>{surveyCounter}</strong>
              </span>
            </div>
          </span>
        </div>

        <div className="form-container">
          <form id="surveyForm" onSubmit={sendForm}>
            <div className="surver-container">
              <div className="left-area">
                <label className="label" htmlFor="province">
                  Ülke: <span className="red">*</span>
                </label>

                <Autocomplete
                  id="country-select"
                  defaultValue={{ name: "Türkiye" }}
                  disableClearable
                  options={countryResults}
                  noOptionsText={"Yükleniyor..."}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={"disAutoFill-" + nameOne}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      required
                    />
                  )}
                  getOptionLabel={(jsonResults) =>
                    jsonResults?.name ? jsonResults?.name : ""
                  }
                  onOpen={getCountries}
                  onChange={(event, option) => {
                    clearProvince();
                    clearDistrict();
                    clearNeighbour();
                    getProvinces(option?.code);
                    setCountry(option?.id);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                />

                <label className="label" htmlFor="province">
                  İl: <span className="red">*</span>
                </label>

                <Autocomplete
                  id="province-select"
                  value={provinceVals}
                  disableClearable
                  options={provinceResults || []}
                  noOptionsText={"Yükleniyor..."}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={"disAutoFill-" + nameTwo}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      required
                    />
                  )}
                  getOptionLabel={(jsonResults) =>
                    jsonResults?.name ? jsonResults?.name : ""
                  }
                  onOpen={loadProvinceList}
                  onChange={(event, option) => {
                    clearDistrict();
                    clearNeighbour();
                    provinceValue(option);
                    getDistricts(option?.id);
                  }}
                />
                <label className="label" htmlFor="city">
                  İlçe:<span className="red">*</span>
                </label>

                <Autocomplete
                  id="district-select"
                  disableClearable
                  options={citiesResults || []}
                  value={dist}
                  noOptionsText={"İl Seçmediniz..."}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={"disAutoFill-" + nameThree}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      required
                    />
                  )}
                  getOptionLabel={(jsonResults) =>
                    jsonResults?.name ? jsonResults?.name : ""
                  }
                  onChange={(event, option) => {
                    distValue(option);
                    getNeighbourHood(option?.id);
                    clearNeighbour();
                  }}
                />

                <label className="label" htmlFor="district">
                  Mahalle:<span className="red">*</span>
                </label>

                <Autocomplete
                  id="neighbourhood-select"
                  value={neighVals}
                  noOptionsText={"İlçe Seçmediniz..."}
                  disableClearable
                  options={neighbourResults || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={"disAutoFill-" + nameFour}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      required
                    />
                  )}
                  getOptionLabel={(jsonResults) =>
                    jsonResults?.name ? jsonResults?.name : ""
                  }
                  onChange={(event, option) => {
                    neighValue(option);
                    setNeighbourhood(option?.id);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                />
              </div>

              <div className="right-area">
                <label className="label" htmlFor="transportation">
                  Ulaşım Yolu:{" "}
                </label>

                <Autocomplete
                  disablePortal
                  noOptionsText={"Yükleniyor..."}
                  id="transportation-select"
                  options={transportationData || []}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(value) => value?.name}
                  onChange={(event, option) => {
                    setTransportation(option?.id);
                  }}
                />
                <div className="gender-div">
                  <p className="label">
                    Cinsiyet: <span class="red">*</span>
                  </p>
                  <RadioGroup
                    row
                    className="radio-container"
                    aria-labelledby="gender"
                    onChange={(e, value) => setGender(value)}
                    name="gender"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio required={true} />}
                      label="Kadın"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio required={true} />}
                      label="Erkek"
                    />
                  </RadioGroup>
                </div>

                <p className="label">
                  Alışveriş Tutarı:<span className="red">*</span>{" "}
                </p>
                <div className="cost label">
                  <TextField
                    required
                    fullWidth
                    id="cost"
                    type="number"
                    inputMode="decimal"
                    inputProps={{ step: "0.01", min: 0 }}
                    onKeyDown={(event) => {
                      if (bannedChars.includes(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleAmount}
                    variant="outlined"
                  />
                </div>
              </div>
            </div>
            <button
              className={`${formSent ? "submit prevent-clicking" : "submit"}`}
              id="send-form-button"
              type="submit"
            >
              Anketi Gönder
            </button>
          </form>
          <div className="wiki-container">
            <a
              className="wiki-link"
              href="https://docs.google.com/presentation/d/1Cxmo7sSHK10WW2zddpD4ymx4ELPF6kVLm8adIPWYC6M/edit?usp=sharing"
              rel="noreferrer"
              target="_blank"
            >
              <div className="wiki-tur">
                <LibraryBooksIcon className="wiki-icon" />
                Türkçe Kılavuz
              </div>
            </a>

            <a
              className="wiki-link"
              href="https://docs.google.com/presentation/d/1ozkPuPCI_KslnkZ9y0T5b2jtIzhgtRMLHmQuFTJCkng/edit?usp=sharing"
              rel="noreferrer"
              target="_blank"
            >
              <div className="wiki-eng">
                <LibraryBooksIcon className="wiki-icon" />
                Userguide
              </div>
            </a>
          </div>
        </div>

        {showSuccess && (
          <Alert className="popup-alert" severity="success">
            Anket başarıyla gönderildi, yanıtınız için teşekkür ederiz
          </Alert>
        )}
        {showFail && (
          <Alert className="popup-alert" severity="error">
            Bir hata ile karşılaşıldı, lütfen destek isteyin
          </Alert>
        )}
      </div>
    </Box>
  );
};

export default Homepage;
