import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../pages/Login";
import { NotFound } from "../pages/NotFound";
import { FedidLogin } from "../pages/FedidLogin";
import Homepage from "../pages/Homepage";

import "../App.css";

const MasterRouter = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<FedidLogin />} />
        <Route exact path="/homepage" element={<Homepage />} />
        <Route exact path="/notfound" element={<NotFound />} />
        <Route path="*" element={<Homepage />} />
      </Routes>
    </Router>
  );
};

export default MasterRouter;
