import React, { useEffect } from "react";
import { dateAdd } from "../services/utils";
import { useNavigate } from "react-router-dom";
import { get, set } from "../services/cache";

export const FedidLogin = () => {
  const redirect = useNavigate();

  let code = new URL(window.location.href).searchParams.get("code");

  const code_verifier = get("fedidCodeVerifier");

  useEffect(() => {
    const urlDecoded = {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_CLIENTID,
      code,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      code_verifier: code_verifier.data,
    };

    var urlEncoded = [];
    for (var property in urlDecoded) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(urlDecoded[property]);
      urlEncoded.push(encodedKey + "=" + encodedValue);
    }

    let body = urlEncoded.join("&");

    fetch(process.env.REACT_APP_FEDID_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body,
    })
      .then((response) => response.json())
      .then((data) => {
        set({
          key: "fedidLoginAccessToken",
          data: data,
          expires: dateAdd(new Date(), "second", 7199).toISOString(),
        });

        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/login`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${data.access_token}`,
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        });
      });
    setTimeout(() => {
      redirect("/homepage");
    }, 2000);
  }, []);

  return <></>;
};
